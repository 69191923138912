import { transition } from "CKJS/utils"
import { create } from "CKJS/utils/dom"
import css from "./overlay.css"

const OverlayContainer = ({ children }) => {
  return create(
    "div",
    {
      data: { object: "overlay" },
      class: "seva-overlay formkit-overlay",
      style: { display: "none" }
    },
    [...children, create("style", {}, [css])]
  )
}

export default class Overlay {
  constructor(modal) {
    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
    this.handleClick = this.handleClick.bind(this)

    this.body = OverlayContainer({ children: [modal.body] })

    this.body.addEventListener("click", this.handleClick)
  }

  handleClick(event) {
    if (event.target == this.body) {
      // Only hide if the click was on the overlay itself
      this.hide()
    }
  }

  show(callback) {
    this.body.style.display = "flex"
    this.toggle(this.body, true, callback)
  }

  hide() {
    transition(
      this.body,
      () => {
        this.toggle(this.body, false)
      },
      () => {
        this.body.style.display = "none"
        this.body.dispatchEvent(
          new CustomEvent("ckjs:overlay:hide", { bubbles: true })
        )
      }
    )
  }

  toggle(element, active, callback) {
    requestAnimationFrame(() => {
      element.dataset.active = active
      if (callback) {
        requestAnimationFrame(callback)
      }
    })
  }
}
