export default class ResponsiveObserver {
  constructor() {
    this.interval = 100
    this.minimumReportingWidth = 300
    this.steps = []
  }

  observe(element) {
    if (typeof window.ResizeObserver != "undefined") {
      // ResizeObserver is supported
      this.useResizeObserver(element)
    } else {
      // Default to MutationObserver
      this.useMutationObserver(element)
    }
  }

  useResizeObserver(element) {
    const observer = new ResizeObserver(entries => {
      this.onObservation(entries, element, this.resizeObserverWidthAccessor)
    })

    observer.observe(element)
  }

  useMutationObserver(element) {
    const MutationObserver =
      window.MutationObserver ||
      window.WebKitMutationObserver ||
      window.MozMutationObserver

    if (typeof MutationObserver == "undefined") {
      return
    }

    const observer = new MutationObserver(entries => {
      this.onObservation(entries, element, this.mutationObserverWidthAccessor)
    })

    observer.observe(element, { childList: true })
  }

  onObservation(entries, element, widthAccessorFunc) {
    entries.forEach(entry => {
      this.recordObservation(entry, element, widthAccessorFunc)
    })
  }

  recordObservation(observationEntry, observedElement, widthAccessorFunc) {
    let steps = this.steps
    const newWidth = widthAccessorFunc.call(this, observationEntry)
    const roundWidth = this.roundedWidth(newWidth, this.interval)
    const maxWidth = this.lastEntry(steps)
    const sizingDirection = this.sizingDirection(roundWidth, maxWidth)

    if (roundWidth == maxWidth) {
      return
    }

    if (sizingDirection == "up" && roundWidth > this.minimumReportingWidth) {
      steps = this.addEntry(steps, roundWidth)
    } else if (sizingDirection == "down") {
      steps = this.popEntry(steps)
    }

    observedElement.setAttribute("min-width", steps.join(" "))
  }

  resizeObserverWidthAccessor(resizeEntry) {
    return resizeEntry.contentRect.width
  }

  mutationObserverWidthAccessor(mutationEntry) {
    return mutationEntry.target.offsetWidth
  }

  roundedWidth(width, interval) {
    return Math.ceil(width / interval) * interval
  }

  addEntry(entries, newEntry) {
    entries.push(newEntry)
    return entries
  }

  popEntry(entries) {
    entries.pop()
    return entries
  }

  lastEntry(entries) {
    return entries[entries.length - 1] || 0
  }

  sizingDirection(minWidth, maxWidth) {
    return minWidth < maxWidth && minWidth != maxWidth ? "down" : "up"
  }
}
