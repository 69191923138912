import bugsnag from "bugsnag-js"

const client = bugsnag({
  apiKey: "c3615a9c832fde860918b61db9e9bea1",
  autoBreadcrumbs: false,
  autoCaptureSessions: false,

  beforeSend: function(report) {
    const line = report.stacktrace[0]
    if (line && line.file.indexOf("ck.") > -1) {
      return true
    }
    report.ignore()
  }
})

export default client
