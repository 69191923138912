export default class Recaptcha {
  static get apiURL() {
    return `https://www.google.com/recaptcha/api.js?render=${Recaptcha.siteKey}`
  }

  static get siteKey() {
    return process.env.RECAPTCHA_SITE_KEY
  }

  constructor({ enabled } = {}) {
    this.enabled = enabled
    if (this.enabled && !this.isLoaded()) {
      this.load()
    }
  }

  async execute(action) {
    try {
      if (!this.enabled) return ""
      await this.ready()
      return await grecaptcha.execute(Recaptcha.siteKey, { action })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error processing recaptcha")
      return ""
    }
  }

  async ready() {
    await this.waitForLoad()
    return new Promise(resolve => {
      grecaptcha.ready(() => {
        resolve()
      })
    })
  }

  async waitForLoad() {
    if (typeof grecaptcha == "undefined") {
      await new Promise(resolve => {
        setTimeout(async () => {
          await this.waitForLoad()
          resolve()
        }, 500)
      })
    }
  }

  isLoaded() {
    return !!document.querySelector(`script[src='${Recaptcha.apiURL}']`)
  }

  load() {
    const script = document.createElement("script")
    script.src = Recaptcha.apiURL
    script.async = true
    script.defer = true

    document.querySelector("head").appendChild(script)
  }
}
