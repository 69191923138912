export default class Guard {
  constructor(url) {
    this.listen = this.listen.bind(this)
    this.clear = this.clear.bind(this)

    this.body = document.createElement("iframe")
    this.body.addEventListener("load", this.loaded.bind(this))
    window.addEventListener("message", this.listen)
    window.addEventListener("ckjs:overlay:remove", this.clear)
    this.body.src = url
  }

  listen(event) {
    switch (event.data.name) {
      case "ckjs:guard:confirmed":
        this.confirmed()
        break
      case "ckjs:guard:loaded":
        this.loaded()
        break
      case "ckjs:guard:size":
        this.change(event.data)
        break
    }
  }

  change(data) {
    const event = new CustomEvent("ckjs:guard:size", {
      detail: data
    })
    this.body.dispatchEvent(event)
  }

  confirmed() {
    const event = new CustomEvent("ckjs:guard:confirmed", { bubbles: true })
    this.body.dispatchEvent(event)
  }

  loaded() {
    const event = new CustomEvent("ckjs:guard:loaded", { bubbles: true })
    this.body.dispatchEvent(event)
  }

  clear() {
    window.removeEventListener("message", this.listen)
    window.removeEventListener("ckjs:overlay:remove", this.clear)
  }
}
