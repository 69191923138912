/**
 * This is used to store forms settings on `window`. When a form is loaded
 * via ck-init.js, it puts the form's settings into the array of forms.
 * The form is registered by its DOM node in order to support multiple forms
 * with the same id.
 *
 * Because this file is loaded by ck-init.js, it should support most browsers
 * without large polyfills.
 */
function initialize() {
  if (!window.__sv_forms) window.__sv_forms = []
}

export function retrieve(element) {
  if (!window.__sv_forms) return null
  // Avoid `.find` for browser support
  for (let i = 0; i < window.__sv_forms.length; i++) {
    if (window.__sv_forms[i].element == element) return window.__sv_forms[i]
  }

  return null
}

function set(element, options) {
  const form = retrieve(element)
  if (!form) {
    window.__sv_forms = [...window.__sv_forms, { element, ...options }]
  }
}

export function register(element, options) {
  initialize()
  let optionsFromDOM = {}
  try {
    optionsFromDOM = JSON.parse(element.dataset.options || null)
  } catch (error) {
    console.error("CK: Error parsing options")
  }
  const id = element.dataset.svForm
  const uid = element.dataset.uid
  const format = element.dataset.format
  set(element, { id, uid, format, ...options, ...optionsFromDOM })
}
