import * as Format from "CKJS/format"
import Recaptcha from "CKJS/recaptcha"
import user from "CKJS/user"
import { create } from "CKJS/utils/dom"
import ResponsiveObserver from "CKJS/responsive-observer"
import { retrieve } from "./registry"

const replaceContent = (element, content, attributes) => {
  const div = create("div", attributes)
  div.innerHTML = content
  element.innerHTML = ""
  element.appendChild(div)
}

const classify = str =>
  str
    .toLowerCase()
    .split(" ")
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join("")

export default class Form {
  constructor(element) {
    this.body = element
    this.config = retrieve(element)
    this.hidden = false

    this.submit = this.submit.bind(this)
    this.visit = this.visit.bind(this)
    this.errors = this.errors.bind(this)
    this.process = this.process.bind(this)
    this.success = this.success.bind(this)
    this.afterSubscribe = this.afterSubscribe.bind(this)
    this.returnVisitor = this.returnVisitor.bind(this)

    this.recaptcha = new Recaptcha(this.config.settings.recaptcha)

    const formatName = this.config.format
      ? classify(this.config.format)
      : "Inline"
    this.format = new Format[formatName](this, this.config)
    this.initialize.call(this)
  }

  // Anything that should only be run once should be here, or in the
  // `initialize` function of each format. Examples include adding event
  // listeners, registering impressions, etc.
  initialize() {
    if (this.config.initialized) return
    this.config.initialized = true
    this.body.addEventListener("submit", this.submit.bind(this))
    this.responsiveObserver = new ResponsiveObserver().observe(this.body)
    if (user.subscribed(this.config.uid)) this.returnVisitor()
    this.format.initialize.call(this.format)
  }

  returnVisitor() {
    const returnVisitorSetting = this.config.settings.return_visitor || {}
    switch (returnVisitorSetting.action) {
      case "hide":
        this.hidden = true
        return
      case "custom_content":
        replaceContent(this.body, returnVisitorSetting.custom_content, {
          class: "seva-custom-content"
        })
        return
    }
  }

  async visit() {
    try {
      const token = await this.recaptcha.execute("formvisit")
      await fetch(`${process.env.BASE_URL}/forms/${this.config.id}/visit`, {
        method: "POST",
        body: JSON.stringify({
          host: document.location.href,
          referrer: document.referrer,
          token: token,
          user: user.id()
        }),
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
    } catch (e) {
      console.log(e)
    }
  }

  async submit(event) {
    if (event) {
      event.preventDefault()
      event.stopPropagation()
    }

    const token = await this.recaptcha.execute("formsubmit")

    const form = this.body
    const formData = new FormData(form)
    const referrer = document.referrer
    formData.append("token", token)
    formData.append("referrer", referrer)
    formData.append("user", user.id())

    try {
      const response = fetch(form.action, {
        method: "POST",
        body: formData,
        headers: {
          Accept: "application/json"
        }
      })

      this.toggle(form.querySelectorAll("input, button"), true)
      this.process(form, await response)
    } catch (error) {
      this.toggle(form.querySelectorAll("input, button"), false)
      this.errors({
        fields: ["server"],
        messages: [error]
      })
    }
  }

  async process(form, response) {
    this.toggle(form.querySelectorAll("input, button"), false)
    if (!response.ok) {
      return this.errors({
        fields: ["server"],
        messages: [response.statusText]
      })
    }
    const json = await response.json()

    switch (json.status) {
      case "success":
        this.success(json)
        break
      default:
        this.errors(json.errors)
        break
    }
  }

  success(json) {
    user.subscribe(this.config.uid)
    if (json.consent.enabled) {
      return this.format.guard(json.consent.url, this.afterSubscribe)
    }

    this.afterSubscribe()
  }

  afterSubscribe() {
    const setting = this.config.settings.after_subscribe
    if (setting.action === "redirect") {
      window.top.location.href = setting.redirect_url
      return
    }

    const success = document.createElement("div")
    success.dataset.element = "success"
    success.dataset.group = "alert"
    success.innerText = setting.success_message

    const fields =
      this.body.querySelector("[data-element='fields']") || this.body
    fields.parentElement.replaceChild(success, fields)
  }

  errors(errors) {
    const container = this.body.querySelector("[data-element='errors']")

    errors.messages.forEach(error => {
      const element = document.createElement("li")
      element.innerText = error
      container.appendChild(element)
    })
  }

  toggle(elements, disabled) {
    elements.forEach(element => {
      element.disabled = disabled
    })
  }

  reset() {
    const container = this.body.querySelector("[data-element='errors']")
    container.innerHTML = ""
  }

  get uid() {
    return this.body.dataset.uid
  }
}
