import Guard from "CKJS/guard"
import Modal from "CKJS/elements/modal"
import { create } from "CKJS/utils/dom"
import { on, onScrollPercentage, onExitIntention } from "CKJS/utils/events"
import device from "CKJS/utils/device"
import css from "./slide-in.css"

const CloseButton = () =>
  create(
    "button",
    {
      class: "formkit-close",
      type: "button",
      data: { formkitClose: "" },
      "aria-label": "Close"
    },
    ["✕"]
  )

const SlideInContainer = ({ children, style }) =>
  create(
    "div",
    { class: "formkit-slide-in", data: { object: "slide-in" }, style },
    [CloseButton(), ...children, create("style", {}, [css])]
  )

export default class SlideIn {
  constructor(form, config) {
    this.form = form
    this.config = config
    this.slideInConfig = config.settings.slide_in
    const style =
      this.slideInConfig.display_in == "bottom_left"
        ? { right: "auto", left: "5%" }
        : {}
    this.body = SlideInContainer({ children: [form.body], style })
  }

  initialize() {
    this.insertIntoDOM()
    this.configureLinks()
    const canDisplay = this.canDisplayOnce() && this.canDisplayOnDevice()
    canDisplay && this.configureTrigger()
  }

  hide = () => {
    this.body.removeAttribute("data-active")
  }

  show = () => {
    this.body.setAttribute("data-active", "")
  }

  canDisplayOnce() {
    const value = parseInt(
      window.localStorage.getItem(
        `ck/forms/slide_in/${this.form.uid}/hideUntil`
      )
    )
    return !value || value < Date.now()
  }

  canDisplayOnDevice() {
    const devices = this.slideInConfig.devices || "all"
    return devices == "all" || device() == devices
  }

  configureLinks() {
    on(document, "a", "click", (event, node) => {
      const dataset = node.dataset
      const formId = dataset && (dataset.svToggle || dataset.formkitToggle)
      if (formId == this.form.uid) {
        event.preventDefault()
        this.show()
      }
    })

    on(this.body, "[data-formkit-close]", "click", () => {
      this.hide()
    })
  }

  configureTrigger() {
    if (this.form.hidden) return
    const config = this.slideInConfig

    const trigger = config.trigger || "timer"
    switch (trigger) {
      case "scroll":
        onScrollPercentage(parseInt(config.scroll_percentage), this.show)
        break
      case "exit":
        onExitIntention(this.show)
        break
      case "timer":
        window.setTimeout(this.show, parseInt(config.timer) * 1000)
        break
    }
  }

  insertIntoDOM = () => {
    document.body.appendChild(this.body)
  }

  guard = (url, callback) => {
    const guard = new Guard(url)
    const modal = new Modal(guard.body)

    modal.body.style.height = "0px"
    modal.body.classList.add("guard")
    modal.insertIntoDOM(document.body)
    modal.show()

    guard.body.addEventListener("ckjs:guard:size", event => {
      modal.body.style.height = `${event.detail.height}px`
    })

    guard.body.addEventListener("ckjs:guard:confirmed", () => {
      modal.hide()
    })

    modal.overlay.body.addEventListener("ckjs:overlay:hide", () => {
      if (callback) {
        callback()
      }
    })
  }
}
