import "CKJS/polyfills"
import Form from "CKJS/form"
import { register } from "CKJS/registry"
/* eslint-disable-next-line no-unused-vars */
import bugsnag from "CKJS/bugsnag"

const sameOrNoVersion = version => element =>
  !element.dataset.version || element.dataset.version == version

// Gets all form elements matching this version of CKJS and all form elements
// with no version specified. HTML embeds will not specify a version on the
// form, but will load whichever CKJS version they have selected with their
// script tags. Since we don't intend to support running multiple versions of
// CKJS for HTML emebeds on the same page, this is safe.
function getFormElements(version) {
  return [].slice
    .call(document.querySelectorAll("[data-sv-form]"))
    .filter(sameOrNoVersion(version))
}

function version() {
  if (process.env.SVJS_VERSION) {
    return process.env.SVJS_VERSION
  } else if (process.env.NODE_ENV == "test") {
    return "test"
  }
  throw "SVJS_VERSION must be defined"
}

export default function initialize() {
  const formElements = getFormElements(version())
  formElements.forEach(formElement => {
    register(formElement)
    new Form(formElement)
  })
}

if (/comp|inter|loaded/.test(document.readyState)) {
  initialize()
} else {
  document.addEventListener("DOMContentLoaded", function() {
    initialize()
  })
}
