import { create } from "CKJS/utils/dom"
import Overlay from "CKJS/elements/overlay"
import { on } from "CKJS/utils/events"

const CloseButton = () =>
  create(
    "button",
    {
      class: "formkit-close",
      type: "button",
      data: { formkitClose: "" },
      "aria-label": "Close"
    },
    ["✕"]
  )

const ModalContainer = ({ children }) =>
  create(
    "div",
    { data: { object: "modal" }, class: "seva-modal formkit-modal" },
    [...children, CloseButton()]
  )

export default class Modal {
  constructor(element) {
    this.hide = this.hide.bind(this)
    this.show = this.show.bind(this)
    this.insertIntoDOM = this.insertIntoDOM.bind(this)

    this.element = element
    this.parent = element.parentElement
    this.body = ModalContainer({ children: [this.element] })
    this.overlay = new Overlay(this)

    on(this.body, "[data-formkit-close]", "click", () => {
      this.hide()
    })
  }

  insertIntoDOM(target) {
    const appendTarget = target || this.parent || document.body
    if (!document.body.contains(appendTarget)) {
      console.error(
        "Form Insertion Error. No place to insert the modal: you need to insert the <script> tag into the <body> of your document"
      )
    }
    appendTarget.appendChild(this.overlay.body)
  }

  show() {
    this.overlay.show(() => {
      this.toggle(this.body, true, () => {
        const input = this.body.querySelector("input")
        input && input.focus()
      })
    })
  }

  hide() {
    this.toggle(this.body, false, this.overlay.hide)
  }

  toggle(element, active, callback) {
    requestAnimationFrame(() => {
      element.dataset.active = active
      if (callback) {
        requestAnimationFrame(callback)
      }
    })
  }
}
