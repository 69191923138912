import Guard from "CKJS/guard"
import Modal from "CKJS/elements/modal"

export default class Inline {
  constructor(form) {
    this.form = form
    this.guard = this.guard.bind(this)
    this.hide = this.hide.bind(this)
  }

  initialize() {
    if (this.form.hidden) {
      this.hide()
      return
    }
    setTimeout(this.form.visit, 2000)
  }

  hide() {
    this.form.body.style.display = "none"
  }

  guard(url, callback) {
    const guard = new Guard(url)
    const modal = new Modal(guard.body)

    modal.body.style.height = "0px"
    modal.body.classList.add("guard")
    modal.insertIntoDOM(this.form.body)
    modal.show()

    guard.body.addEventListener("ckjs:guard:size", event => {
      modal.body.style.height = `${event.detail.height}px`
    })

    guard.body.addEventListener("ckjs:guard:confirmed", () => {
      modal.hide()
    })

    modal.overlay.body.addEventListener("ckjs:overlay:hide", () => {
      if (callback) {
        callback()
      }
    })
  }
}
