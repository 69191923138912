function selectorMatches(element, selector) {
  const p = Element.prototype
  const matches =
    p.matches ||
    p.webkitMatchesSelector ||
    p.mozMatchesSelector ||
    p.msMatchesSelector ||
    function(s) {
      return [].indexOf.call(document.querySelectorAll(s), this) !== -1
    }
  return matches.call(element, selector)
}

export function on(container, selector, event, fn) {
  container.addEventListener("click", event => {
    let node = event.target
    while (
      node != undefined &&
      (node == document || !selectorMatches(node, selector))
    ) {
      node = node.parentNode
    }
    if (node) {
      fn(event, node)
    }
  })
}

/**
 * Calls `fn` once after the window scroll percentage has passed `percentage`
 **/
export function onScrollPercentage(percentage, fn) {
  let ticking = false

  const listener = () => {
    if (ticking) return

    ticking = true

    window.requestAnimationFrame(() => {
      const scrollPercentage =
        ((window.scrollY + window.innerHeight) /
          window.document.body.scrollHeight) *
        100

      if (scrollPercentage > percentage) {
        fn()
        window.removeEventListener("scroll", listener)
      }
      ticking = false
    })
  }

  window.addEventListener("scroll", listener)
  return listener
}

/**
 * Calls `fn` only once when the user appears to be leaving the page.
 **/
export function onExitIntention(fn) {
  const listener = () => {
    fn()
    document.body.removeEventListener("mouseleave", listener)
  }

  document.body.addEventListener("mouseleave", listener)
}
