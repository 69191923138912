function toDOMNode(obj) {
  switch (typeof obj) {
    case "string":
      return document.createTextNode(obj)
    default:
      return obj
  }
}

/**
 * A helper for creating DOM elements.
 *
 * Usage:
 * create("div", { class: "modal" }, [
 *   create("button", { class: "modal-close" }, ["✕"]),
 *   create("div", { class: "modal-content"}, ["Content"])
 * ])
 *
 * <div class="modal">
 *   <button class="modal-close">✕</button>
 *   <div class="modal-content">Content</div>
 * </div>
 **/
export function create(tag, attributes, children) {
  const element = document.createElement(tag)

  for (let key in attributes) {
    if (key == "style") {
      for (let styleKey in attributes[key]) {
        element.style[styleKey] = attributes[key][styleKey]
      }
    } else if (key == "data") {
      for (let dataKey in attributes[key]) {
        element.dataset[dataKey] = attributes[key][dataKey]
      }
    } else if (attributes.hasOwnProperty(key)) {
      element.setAttribute(key, attributes[key])
    }
  }

  if (children && Array.isArray(children) && children.length > 0) {
    children.forEach(function(child) {
      element.appendChild(toDOMNode(child))
    })
  }

  return element
}

export function createElementFromHTML(html) {
  const element = document.createElement("div")
  element.innerHTML = html
  return element.children[0]
}

export function replaceDOMElements(elements, replaceWith) {
  let replaced = []
  for (let i = 0; i < elements.length; i++) {
    const element = elements[i]
    const replaceWithClone = replaceWith.cloneNode(true)
    replaced.push(replaceWithClone)
    element.parentElement.replaceChild(replaceWithClone, element)
  }
  return replaced
}
