const callback = window.requestIdleCallback || window.requestAnimationFrame
export const idleCallback = callback.bind(window)

export const transition = (element, fn, callback) => {
  function listener() {
    callback()
    element.removeEventListener("transitionend", listener)
  }
  element.addEventListener("transitionend", listener)
  fn()
}
