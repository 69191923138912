import "whatwg-fetch"
import "custom-event-polyfill"
import "regenerator-runtime/runtime"
import "core-js/fn/array/find"

// NodeList forEach
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function(callback, thisArg) {
    thisArg = thisArg || window
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this)
    }
  }
}

// localStorage
try {
  const test = "test"
  localStorage.setItem(test, test)
  localStorage.removeItem(test)
} catch (e) {
  window.localStorage = {
    _data: {},
    setItem: function(id, val) {
      return (this._data[id] = String(val))
    },
    getItem: function(id) {
      return this._data.hasOwnProperty(id) ? this._data[id] : undefined
    },
    removeItem: function(id) {
      return delete this._data[id]
    },
    clear: function() {
      return (this._data = {})
    }
  }
}
