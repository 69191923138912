import Guard from "CKJS/guard"
import ModalElement from "CKJS/elements/modal"
import { on, onScrollPercentage, onExitIntention } from "CKJS/utils/events"
import device from "CKJS/utils/device"

export default class Modal {
  constructor(form, config) {
    this.form = form
    this.config = config
    this.modalConfig = config.settings.modal
    this.modal = new ModalElement(form.body)
  }

  initialize() {
    this.modal.insertIntoDOM()
    this.configureLinks()
    const canDisplay = this.canDisplayOnce() && this.canDisplayOnDevice()
    canDisplay && this.configureTrigger()
  }

  configureLinks() {
    on(document, "a", "click", (event, node) => {
      const dataset = node.dataset
      const formId = dataset && (dataset.svToggle || dataset.formkitToggle)
      if (formId == this.form.uid) {
        event.preventDefault()
        this.show()
      }
    })
  }

  canDisplayOnce() {
    const value = parseInt(
      window.localStorage.getItem(`ck/forms/modal/${this.form.uid}/hideUntil`)
    )
    return !value || value < Date.now()
  }

  canDisplayOnDevice() {
    const devices = this.modalConfig.devices || "all"
    return devices == "all" || device() == devices
  }

  configureTrigger() {
    if (this.form.hidden) return
    const config = this.modalConfig

    const trigger = config.trigger || "timer"
    switch (trigger) {
      case "scroll":
        onScrollPercentage(parseInt(config.scroll_percentage), this.show)
        break
      case "exit":
        onExitIntention(this.show)
        break
      case "timer":
        window.setTimeout(this.show, parseInt(config.timer) * 1000)
        break
    }
  }

  show = () => {
    const config = this.modalConfig
    this.modal.show()
    this.form.visit()

    if (config.show_once_every) {
      window.localStorage.setItem(
        `ck/forms/modal/${this.form.uid}/hideUntil`,
        this.millisecondsSinceEpoch(config.show_once_every)
      )
    }

    if (this.disable) {
      this.disable()
    }
  }

  guard(url, callback) {
    const guard = new Guard(url)
    const modalContent = this.modal.body.firstChild
    const modalHeight = modalContent.offsetHeight
    guard.body.style.height = "0px"
    this.modal.body.appendChild(guard.body)

    guard.body.addEventListener("ckjs:guard:loaded", () => {
      modalContent.style.height = "0px"
    })

    guard.body.addEventListener("ckjs:guard:size", event => {
      guard.body.style.height = `${event.detail.height}px`
    })

    guard.body.addEventListener("ckjs:guard:confirmed", () => {
      guard.body.remove()
      modalContent.style.height = `${modalHeight}px`
      if (callback) {
        callback()
      }
    })
  }

  millisecondsSinceEpoch(offsetInDay) {
    return Date.now() + parseInt(offsetInDay) * 24 * 60 * 60 * 1000
  }
}
